<template>
    <div v-if="pageContent !== null">
        <Draggable
            v-model="pageContent.components"
            item-key="i"
            handle=".handle"
            tag="transition-group"
            v-bind="dragOptions"
            :component-data="{
                tag: 'div',
                type: 'transition-group',
                name: !drag ? 'flip-list' : null,
            }"
            class="grid"
            style="margin-top: 0.5em"
            @start="drag = true"
            @end="dragOff"
        >
            <template #item="{ element }">
                <div :key="element.i" :class="'col-12 md:col-6 lg:col-4 xl:col-3'">
                    <!-- <div v-for="col in element" :key="col.id" class="p-col"> -->
                    <component
                        :is="element.component"
                        :content="element"
                        :dragObj="element"
                        :valueObj="element.data"
                        :dataObj="element.data"
                        :styleObj="element.style"
                        :key="element.i"
                        @entrySave="
                            ($value) => {
                                changeVal($value);
                            }
                        "
                        @entryDuplicate="
                            ($value) => {
                                duplicateVal($value);
                            }
                        "
                        @entryDelete="
                            ($value) => {
                                deleteVal($value);
                            }
                        "
                    ></component>
                    <!-- </div> -->
                    <!-- <dashboardPanelValues :dragObj="element" :valueObj="paramList2"></dashboardPanelValues> -->
                </div>
            </template>
        </Draggable>
        
    </div>
</template>

<script>
// import ProductService from "../helpers";
import SocketioService from '@/services/socketioService.js';
// import mainService from "@/services/mainService.js";
import { addEventListener, removeEventListener } from '@/services/eventListener';
import { getRandomInt } from '../helpers';

export default {
    name: 'weather',
    mixins: [addEventListener, removeEventListener],
    components: {},
    data() {
        return {
            loading: false,
            pageContent: null,
            drag: false,
            columns: [],
            collapsed: true,
            interval: undefined,
            list: [{ name: 'Wetterstation', id: 0 }],
            item: [
                {
                    name: 'mainpage1',
                    label: 'Main Page1',
                    content: 'Hallo Welt',
                    type: 'grid',
                    style: {
                        background: 'proGray',
                        foreground: 'white',
                    },
                    components: [
                        {
                            i: 52097,
                            name: '',
                            label: '',
                            component: 'dashboardValueBox',
                            width: 2,
                            style: {
                                background: 'lightCrimson',
                                foreground: 'white',
                                pattern: 'vlines',
                            },
                            data: {
                                id: '61d77939fa55902fb1b00bf8',
                            },
                            id: 1344,
                        },
                    ],
                },
            ],
        };
    },
    props: {
        // paramList: Object,
    },
    computed: {
        dragOptions() {
            return {
                animation: 200,
                group: 'description',
                disabled: false,
                ghostClass: 'ghost',
            };
        },
    },
    created() {},
    methods: {
        // getParameter() {
        //    mainService.apiClient
        //     .get("?system=dateTime&system=status&system=copyright")
        //     .then((response) => {
        //       const sysStat = response.data;
        //       this.$store.dispatch("setSystemStatus", sysStat);
        //     })
        //     .catch((error) => {
        //       console.log(error);
        // },
        updateVals() {
            this.paramList = this.$store.getters.getParameterList;
            // this.paramList2 = this.$store.getters.getParameterList2;
            this.paramListContext = this.$store.getters.getParameterListContext;
            this.getParameterStatusIO();
        },
        getPageData() {
            this.loading = true;
            if (this.siteName !== null) {
                SocketioService.getServerData('page', 'weather', (err, response) => {
                    this.pageContent = response[0];
                    // console.log(response);
                    // Object.entries(this.view_menu).forEach(([, value], index) => {
                    //   if (index === 0) {
                    //     this.view_menu_selection[0] = "ROOT";
                    //   }
                    //   this.view_menu_selection[index + 1] = value.label;
                    //   this.view_menu_index.push(index);
                    // });
                    // console.log(this.view_menu_selection);

                    // SocketioService.getServerData("status", "io", (response) => {
                    //   response.forEach((element) => {
                    //     store.dispatch("setValues", element);
                    //     // console.log(element);
                    //   });
                    //   this.loading = false;
                    // });

                    this.loading = false;

                    // SocketioService.getStatusIO((response) => {
                    //   // this.paramList = response;
                    //   // this.loading = false;
                    //   response.forEach((element) => {
                    //     store.dispatch("setValues", element);
                    //     // console.log(element);
                    //     this.loading = false;
                    //   });
                    // });

                    // this.loading = false;
                    console.log(this.pageContent);

                    // if (this.pageContent.type === 'grid') {
                    //     this.$store.dispatch('setPageData', { editMode: true });
                    // }
                });
            }
        },
        getParameterStatusIOTree() {
            // this.$store.dispatch('getIoTree', null);
        },
        getOPCAppTree() {
            SocketioService.getOPCAppTree((response) => {
                this.opcTree = response;
                // console.log(response);
                // this.loading = false;
            });
        },
        dragOff(event) {
            console.log(event);
            this.changeVal(null);
            this.drag = false;
            // console.log(this.grids3);
        },
        getIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.id === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        getGridIndex(id) {
            var index = this.pageContent.components.findIndex((x) => x.i === id);
            if (index === -1) {
                // this.values.push(vars);
                console.log('NOT FOUND');
                return -1;
            } else {
                // this.values[index] = vars;
                // return this.pageContent.components[index];
                return index;
            }
        },
        onNodeSelect(node) {
            this.$toast.add({
                severity: 'success',
                summary: 'Node Selected',
                detail: node,
                life: 3000,
            });
        },
        changeVal(entry) {
            console.log(entry);
            if (entry !== null) {
                // const index = this.getIndex(entry.id);
                const index = this.getGridIndex(entry.i);
                console.log('INDEX is: ' + index);
                if (index === -1) {
                    this.pageContent.components.push(entry);
                } else {
                    this.pageContent.components[index] = entry;
                }
            }
            // SocketioService.setServerData(
            //   "setPage",
            //   "sub",
            //   this.view_menu[_index],
            //   (response) => {
            //     console.log(response);
            //     this.getMenuList();
            //   }
            // );
            SocketioService.setServerData('setPage', true, this.pageContent, (err, response) => {
                // console.log(response);
                // this.getMenuList();
                if (!err) {
                    if (response !== null) {
                        // this.getParameterStatusIO();
                        // document not changed
                        if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
                            this.$toast.add({
                                severity: 'info',
                                summary: 'Seite unverändert',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ' + this.pageContent.label,
                                life: 3000,
                            });
                            // document updated
                        } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
                            this.getPageData();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite geändert',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ' + this.pageContent.label,
                                life: 3000,
                            });
                            // document deleted
                        } else if (response.acknowledged && response.deletedCount > 0) {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite gelöscht',
                                detail: response.deletedCount + ' Einträge erfolgreich gelöscht',
                                life: 3000,
                            });
                            // document created
                        } else if (response.acknowledged && response.insertedId !== null) {
                            this.getPageData();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite erstellt',
                                detail: 'Seite mit ID [' + response.insertedId.toString() + '] erfolgreich erstellt',
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Seitenänderung nicht durchgeführt',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
                                life: 3000,
                            });
                        }
                    }
                }
            });
        },
        addWidget(settings) {
            let index;
            let widget;
            // this.grids = [...this.grids, 1];
            // index = (this.grids.length - 1);
            index = this.pageContent.components.length;
            console.log(index);
            // this.pageContent.components[index] = [];
            console.log(settings);
            if (settings !== null) {
                widget = { ...settings };
                widget.y = widget.y + 2;
                // widget.id = index;
                widget.i = getRandomInt(0, 65535);
            } else {
                widget = {
                    // id: index,
                    // id: getRandomInt(0, 65535),
                    i: getRandomInt(0, 65535),
                    name: 'widget',
                    label: 'Widget',
                    component: 'dashboardWeatherBox',
                    width: 2,
                    style: {
                        background: 'darkBlue',
                        foreground: 'white',
                    },
                    data: {
                        id: '01',
                    },
                };
            }
            // this.pageContent.components.push(widget);
            this.changeVal(widget);
            // console.log(this.pageContent.components);

            // index2 = this.pageContent.components[index].length;
            // console.log(index2);
            // this.pageContent.components[index][index2] = {};
            // this.pageContent.components[index][index2].id = index + ":" + index2;
            // this.pageContent.components[index][index2].name = "GUSCH";
            // console.log(this.grids);
        },
        duplicateVal(entry) {
            this.addWidget(entry);
        },
        deleteVal(entry) {
            // console.log(entry);
            // const index = this.getIndex(entry.id);
            const index = this.getGridIndex(entry.i);
            // console.log(index);
            this.pageContent.components.splice(index, 1);
            // SocketioService.setServerData(
            //   "setPage",
            //   "sub",
            //   this.view_menu[_index],
            //   (response) => {
            //     console.log(response);
            //     this.getMenuList();
            //   }
            // );

            SocketioService.setServerData('setPage', true, this.pageContent, (err, response) => {
                // console.log(response);
                // this.getMenuList();
                if (!err) {
                    if (response !== null) {
                        // this.getParameterStatusIO();
                        // document not changed
                        if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount === 0) {
                            this.$toast.add({
                                severity: 'info',
                                summary: 'Seite unverändert',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , keine Änderung notwendig ' + this.pageContent.label,
                                life: 3000,
                            });
                            // document updated
                        } else if (response.acknowledged && response.matchedCount > 0 && response.upsertedCount === 0 && response.modifiedCount > 0) {
                            this.getPageData();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite geändert',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , alle Änderungen wurden gespeichert ' + this.pageContent.label,
                                life: 3000,
                            });
                            // document deleted
                        } else if (response.acknowledged && response.deletedCount > 0) {
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite gelöscht',
                                detail: response.deletedCount + ' Einträge erfolgreich gelöscht',
                                life: 3000,
                            });
                            // document created
                        } else if (response.acknowledged && response.insertedId !== null) {
                            this.getPageData();
                            this.$toast.add({
                                severity: 'success',
                                summary: 'Seite erstellt',
                                detail: 'Seite mit ID [' + response.insertedId.toString() + '] erfolgreich erstellt',
                                life: 3000,
                            });
                        } else {
                            this.$toast.add({
                                severity: 'error',
                                summary: 'Seitenänderung nicht durchgeführt',
                                detail: 'modifiziert: ' + response.modifiedCount + ' , Erstellt: ' + response.upsertedCount + ' [' + response.upsertedId + ']',
                                life: 3000,
                            });
                        }
                    }
                }
            });
        },
    },
    mounted() {
        this.getPageData();
        // this.paramList = this.$store.getters.getParameterList;
        this.getParameterStatusIOTree();
        // this.paramListContext = this.$store.getters.getParameterListContext;
        // this.columns = this.paramList;
    },
};
</script>

<style lang="scss" scoped>

</style>
